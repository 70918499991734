<template>
  <div>
    <v-layout wrap pt-5 justify-center>
      <v-flex xs11 text-left>
        <span
          style="color: #1f1f1f; font-family: poppinssemibold; font-size: 18px"
          >Cancellation Policy</span
        >
      </v-flex>
      <v-flex xs12 sm11 md11 lg11 xl11 pt-5 pb-16 text-justify>
        <span
          style="font-family: poppinsregular; font-size: 15px; color: #1a1919"
          v-html="pageData.details"
        ></span>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data() {
    return {
      pageData: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      axios({
        method: "GET",
        url: "/cancellationPolicy",
        // params: {
        //   Name: "Terms and Conditions",
        // },
      })
        .then((response) => {
          this.pageData = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>